
import { reactive } from 'vue'
import useEmitter from '@/composables/Emmiter';

interface User {
    date: string
    name: string
    size: string
    link: string
}

export default {
    name: 'arquivo-exportado',
    setup() {
        const collapseControl = reactive({
            agregados: true,
            aLaCarte: false,
            fabrica: false,
            pneus: false,
            servico: false,
        })
        const emitter = useEmitter();

        emitter.on("filter-arquivos-exportados", async data => {
            console.log(data)
        });

        const tableData: User[] = [
            {
                date: '05/08/2023 10:10:40',
                name: 'EXPORTACAO_PECAS_ATRIA_TESTES_BMW1693919440.xlsx',
                size: '10',
                link: '#'
            },
            {
                date: '05/07/2023 10:04:40',
                name: 'EXPORTACAO_PECAS_ATRIA_TESTES_BMW1693919440.xlsx',
                size: '15',
                link: '#'
            },
            {
                date: '05/10/2023 10:02:39',
                name: 'EXPORTACAO_PECAS_ATRIA_TESTES_BMW1693919440.xlsx',
                size: '25',
                link: '#'
            },
            {
                date: '05/11/2023 10:02:39',
                name: 'EXPORTACAO_PECAS_ATRIA_TESTES_BMW1693919440.xlsx',
                size: '7',
                link: '#'
            },
        ]

        const tableVazia = []

        return {
            tableData,
            collapseControl,
            tableVazia
        }
    }
}
